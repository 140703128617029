import * as React from 'react'
import { navigate, PageProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import { getRedirectLanguage } from '../utils'
import Layout from '../containers/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import awsmobile from '../aws-exports'

const ContactPage: React.FC<PageProps> = ({ location, pageContext }) => {
  React.useEffect(() => {
    const urlLang = getRedirectLanguage()
    if (location.pathname.startsWith('/en/') && urlLang === '/') {
      const newPathname = location.pathname.replace('/en/', '/')
      void navigate(newPathname)
    }
  }, [location.pathname])
  const intl = useIntl()

  const inquiryApiURL = `${awsmobile.aws_cloud_logic_custom[0].endpoint}/inquiry`
  const [sendData, setSendData] = React.useState({
    name: '',
    company: '',
    email: '',
    title: '',
    comments: '',
  })
  const submitButtonRef = React.useRef<HTMLButtonElement>(null)

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target

    switch (name) {
      case 'name':
      case 'company':
      case 'email':
      case 'title':
      case 'comments': {
        const nextData = sendData
        nextData[name] = value
        setSendData(nextData)
        break
      }
      default: {
        break
      }
    }
  }

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (submitButtonRef.current) {
      submitButtonRef.current.disabled = !submitButtonRef.current.disabled
    }

    const data = new FormData()
    Object.entries(sendData).forEach(([key, value]) => {
      data.append(key, value)
    })
    await fetch(inquiryApiURL, {
      method: 'POST',
      body: data,
    })
    void navigate('/thanks')
    if (submitButtonRef.current) {
      submitButtonRef.current.disabled = !submitButtonRef.current.disabled
    }
  }

  return (
    <Layout id="content" subtitle="Contact" pageContext={pageContext}>
      <section id="top">
        <Header location={location} pageContext={pageContext} />
        <div className="inner">
          <h1 className="title fadeup">
            {intl.formatMessage({ id: 'contact_title' })}
          </h1>
        </div>
      </section>

      <div className="content-wrap">
        <section className="contact-form">
          <p className="form-message fadeup">
            {intl.formatMessage({ id: 'contact_text' })}
          </p>
          <form className="fadeup-delay" onSubmit={submitHandler}>
            <label htmlFor="input-name">
              <p className="label-area">
                {intl.formatMessage({ id: 'contact_form_name' })}
                <span className="required-mark">*</span>
              </p>
              <p className="input-area">
                <input
                  type="text"
                  name="name"
                  size={40}
                  placeholder={intl.formatMessage({
                    id: 'contact_placeholder_name',
                  })}
                  id="input-name"
                  onChange={changeHandler}
                />
              </p>
            </label>
            <label htmlFor="input-company">
              <p className="label-area">
                {intl.formatMessage({ id: 'contact_form_company' })}
              </p>
              <p className="input-area">
                <input
                  type="text"
                  name="company"
                  size={40}
                  placeholder={intl.formatMessage({
                    id: 'contact_placeholder_company',
                  })}
                  id="input-company"
                  onChange={changeHandler}
                />
              </p>
            </label>
            <label htmlFor="input-email">
              <p className="label-area">
                {intl.formatMessage({ id: 'contact_form_email' })}
                <span className="required-mark">*</span>
              </p>
              <p className="input-area">
                <input
                  type="text"
                  name="email"
                  size={40}
                  placeholder={intl.formatMessage({
                    id: 'contact_placeholder_email',
                  })}
                  id="input-email"
                  onChange={changeHandler}
                />
              </p>
            </label>
            <label htmlFor="input-title">
              <p className="label-area">
                {intl.formatMessage({ id: 'contact_form_title' })}
                <span className="required-mark">*</span>
              </p>
              <p className="input-area">
                <input
                  type="text"
                  name="title"
                  size={40}
                  placeholder={intl.formatMessage({
                    id: 'contact_placeholder_title',
                  })}
                  id="input-title"
                  onChange={changeHandler}
                />
              </p>
            </label>
            <label htmlFor="input-comments">
              <p className="label-area">
                {intl.formatMessage({ id: 'contact_form_message' })}
                <span className="required-mark">*</span>
              </p>
              <p className="input-area">
                <textarea
                  name="comments"
                  rows={2}
                  cols={40}
                  id="input-comments"
                  onChange={changeHandler}
                />
              </p>
            </label>
            <button className="btn-black" type="button" ref={submitButtonRef}>
              <input type="submit" value="Send" />
            </button>
          </form>
        </section>
      </div>

      <Footer location={location} />
    </Layout>
  )
}

export default ContactPage
